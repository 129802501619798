import React, { useLayoutEffect } from "react"
import Navbar from "./Navbar"
import Home from "./container/Home"
import Services from "./container/Services"
import Portfolio from "./container/Portfolio"
import ToolsAndExperiences from "./container/ToolsAndExperiences"
import Connect from "./container/Connect"
import ScrollBar from "./ScrollBar"
import Menu from "./Menu"
import Backdrop from "./Backdrop"
import Preloader from "./Preloader"
import "./index.scss"

export default function App(){
    const [uiSettings, setUiSettings] = React.useState({
        showMenu:false,
        showPreloader:true,
        showing:"preloader",
        isFrench: false,
    })

    function toggleLanguage(){
        setUiSettings((prevState)=>{return({...prevState, showMenu:false, isFrench: !prevState.isFrench})})
    }

    function toggleMenu(){
        setUiSettings((prevState)=>{return({...prevState, showMenu: !prevState.showMenu})})
    }

    function gotoHome(){
        setUiSettings((prevState)=>{
            return({
                ...prevState,
                showMenu:false,
                showing:"home"
            })
        })
    }
    
    function gotoServices(){
        setUiSettings((prevState)=>{
            return({
                ...prevState,
                showMenu:false,
                showing:"services"
            })
        })
    }

    function gotoPortfolio(){
        setUiSettings((prevState)=>{
            return({
                ...prevState,
                showMenu:false,
                showing:"portfolio"
            })
        })
    }

    function gotoExperience(){
        setUiSettings((prevState)=>{
            return({
                ...prevState,
                showMenu:false,
                showing:"experience"
            })
        })
    }

    function gotoContact(){
        setUiSettings((prevState)=>{
            return({
                ...prevState,
                showing:"contact",
                showMenu:false
            })
        })
    }

    React.useEffect(
        function(){
            setTimeout(function(){
                setUiSettings((prevState)=>{
                    return({...prevState, showPreloader: false, showing: "home"})
                })
            }, 3000)
        }, []
    )

    useLayoutEffect(()=>{
        const showing = uiSettings?.showing
        const showMenu = uiSettings?.showMenu
        function updateThemeColor(color) {
          let themeColorMeta = document.querySelector('meta[name="theme-color"]');
    
          if (themeColorMeta) {
            themeColorMeta.setAttribute("content", color);
          } else {
            themeColorMeta = document.createElement("meta");
            themeColorMeta.setAttribute("name", "theme-color");
            themeColorMeta.setAttribute("content", color);
    
            document.getElementsByTagName("head")[0].appendChild(themeColorMeta);
          }
        }

        if(showing === "home"){
            updateThemeColor('#FFFFFF')
            if(showMenu){
                updateThemeColor('#7851a9')
            }
        } else if(showing === "services"){
            updateThemeColor('#FFFFFF')
            if(showMenu){
                updateThemeColor('#7851a9')
            }
        } else if(showing === "portfolio"){
            updateThemeColor('#7851a9')
            if(showMenu){
                updateThemeColor('#FFFFFF')
            }
        }  else if(showing === "experience"){
            updateThemeColor('#d7c6eb')
            if(showMenu){
                updateThemeColor('#7851a9')
            }
        }  else if(uiSettings?.showPreloader){
            updateThemeColor('#FFFFFF')
        } else{
            updateThemeColor('#7851a9')
            if(showMenu){
                updateThemeColor('#FFFFFF')
            }
        }
    },[uiSettings.showing, uiSettings.showMenu])


    return(
        <div className={`container showing-${uiSettings.showing}`}>
            
            <Navbar
                className="navbar"
                toggleMenu={toggleMenu}
                showMenu={uiSettings.showMenu}
                gotoHome={gotoHome}
                gotoServices={gotoServices}
                gotoPortfolio={gotoPortfolio}
                gotoExperience={gotoExperience}
                gotoContact={gotoContact}
                showing={uiSettings.showing}
                toggleLanguage={toggleLanguage}
                isFrench={uiSettings.isFrench}
                />

            <ScrollBar
                className={uiSettings.showMenu?"scrollbar show":"scrollbar"}
                gotoHome={gotoHome}
                gotoServices={gotoServices}
                gotoPortfolio={gotoPortfolio}
                gotoExperience={gotoExperience}
                gotoContact={gotoContact}
                showing={uiSettings.showing}
                />

            <Home
                className="home section"
                swipeUp={gotoContact}
                swipeDown={gotoServices}
                isFrench={uiSettings.isFrench}
                />

            <Services
                className="services section"
                swipeUp={gotoHome}
                swipeDown={gotoPortfolio}
                gotoContact={gotoContact}
                isFrench={uiSettings.isFrench}
                />

            <Portfolio
                className="portfolio section"
                swipeUp={gotoServices}
                swipeDown={gotoExperience}
                isFrench={uiSettings.isFrench}
                />

            <ToolsAndExperiences
                className="experience section"
                swipeUp={gotoPortfolio}
                swipeDown={gotoContact}
                isFrench={uiSettings.isFrench}
                />

            <Connect
                className="contact section"
                swipeUp={gotoExperience}
                swipeDown={gotoHome}
                isFrench={uiSettings.isFrench}
                />

            <Menu
                className={uiSettings.showMenu?"show menu":"menu"}
                gotoHome={gotoHome}
                gotoServices={gotoServices}
                gotoPortfolio={gotoPortfolio}
                gotoExperience={gotoExperience}
                gotoContact={gotoContact}
                showing={uiSettings.showing}
                toggleLanguage={toggleLanguage}
                isFrench={uiSettings.isFrench}
                />
            <Backdrop className={uiSettings.showMenu?"show backdrop":"backdrop"} />
            <Preloader className={uiSettings.showPreloader?"preloader":"preloader hide"} />

        </div>
    )
}