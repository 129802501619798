import React from "react";

export default function ToolsAndExperiences(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const experience = document.getElementById("experience")
        experience.addEventListener("touchend", touchEnd)
        function touchEnd(event){
            const endY = event.changedTouches[0].clientY
            if(endY > startY + 200){
                // props.swipeUp()
                return
            } else if(endY < startY - 200) {
                // props.swipeDown()
                return
            }
        }
    }

    return(
        <div id="experience" className={props.className} onTouchStart={touchStarted}>
            <div className="content">
                <h1>{props.isFrench?"Outils & Expérience":"Tools & Experience"}</h1>
                <div className="tne-holder">
                    <div className="tools">
                        <div className="tool html">
                            <img src="html.png" alt="html" />
                        </div>
                        <div className="tool css">
                            <img src="css.png" alt="css" />
                        </div>
                        <div className="tool js">
                            <img src="javascript.png" alt="javascript" />
                        </div>
                        <div className="tool react">
                            <img src="react.png" alt="react" />
                        </div>
                        <div className="tool nextjs">
                            <img src="https://w7.pngwing.com/pngs/87/586/png-transparent-next-js-hd-logo.png" alt="phonegap" />
                        </div>
                        <div className="tool ethereum">
                            <img src="https://cdn-icons-png.flaticon.com/128/15301/15301597.png" alt="eth-dev" />
                        </div>
                        <div className="tool cardano">
                            <img src="https://cdn-icons-png.flaticon.com/128/10464/10464556.png" alt="cardano" />
                        </div>
                        <div className="tool sass">
                            <img src="sass.png" alt="sass" />
                        </div>
                        <div className="tool tailwind">
                            <img src="https://www.pngrepo.com/download/354431/tailwindcss-icon.png" alt="phonegap" />
                        </div>
                        <div className="tool figma">
                            <img src="figma.png" alt="figma" />
                        </div>
                        <div className="tool wordpress">
                            <img src="https://cdn-icons-png.flaticon.com/128/174/174881.png" alt="wordpress" />
                        </div>
                        <div className="tool git">
                            <img src="git.png" alt="git" />
                        </div>
                    </div>
                    <div className="xp">
                        <div className="work">
                            <div className="year">2024</div>
                            <div className="company">SpaceLabz</div>
                            <div className="title">{props.isFrench?"Développeur React":"Web3 Developer & UI designer"}</div>
                        </div>
                        <div className="work">
                            <div className="year">2024</div>
                            <div className="company">Facile Studaire</div>
                            <div className="title">{props.isFrench?"Développeur React":"FullStack Developer"}</div>
                        </div>
                        <div className="work">
                            <div className="year">2024</div>
                            <div className="company">FirepipsFX</div>
                            <div className="title">{props.isFrench?"Développeur React":"Frontend Developer & UI designer"}</div>
                        </div>
                        <div className="work">
                            <div className="year">2024</div>
                            <div className="company">CNFTshirt</div>
                            <div className="title">{props.isFrench?"Développeur React":"Web3 Developer & UI designer"}</div>
                        </div>
                        <div className="work">
                            <div className="year">2023</div>
                            <div className="company">FirepipsFX</div>
                            <div className="title">{props.isFrench?"Développeur React":"Frontend Developer"}</div>
                        </div>
                        <div className="work over">
                            <div className="year">2023</div>
                            <div className="company">ShopTinga</div>
                            <div className="title">{props.isFrench?"Développeur Frontal":"Snr Frontend Developer"}</div>
                        </div>
                        <div className="work over">
                            <div className="year">2023</div>
                            <div className="company">ShopTinga</div>
                            <div className="title">{props.isFrench?"Développeur Frontal":"Frontend Developer"}</div>
                        </div>
                        <div className="work">
                            <div className="year">2023</div>
                            <div className="company">Ignition labz</div>
                            <div className="title">{props.isFrench?"Développeur Web":"Web Developer"}</div>
                        </div>
                        <div className="work over">
                            <div className="year">2022</div>
                            <div className="company">Xerax labs.inc</div>
                            <div className="title">{props.isFrench?"Développeur Frontal":"Junior Frontend Developer"}</div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="time.png" alt="clock" className="clock" />
        </div>
    )
}
