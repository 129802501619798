import React from "react";

export default function Menu(props){
    return(
        <div className={props.className}>
            <div className="mobile-links-holder">
                <div onClick={props.gotoHome} className={props.showing === "home"?"mobile-link active":"mobile-link"}>Home</div>
                <div onClick={props.gotoServices} className={props.showing === "services"?"mobile-link active":"mobile-link"}>Services</div>
                <div onClick={props.gotoPortfolio} className={props.showing === "portfolio"?"mobile-link active":"mobile-link"}>Portfolio</div>
                <div onClick={props.gotoExperience} className={props.showing === "experience"?"mobile-link active":"mobile-link"}>Experience</div>
                <div onClick={props.gotoContact} className={props.showing === "contact"?"mobile-link active":"mobile-link"}>Contact</div>
            </div>
            <div className={props.isFrench?"switch-langg isfrench":"switch-langg"} onClick={props.toggleLanguage}>
                <img src="uk-flag.png" alt="english-uk" />
                <img src="france-flag.png" alt="french" />
            </div>
        </div>
    )
}