import React from "react";

export default function ScrollBar(props){
    return(
        <div id="scrollbar" className={props.className}>
            <div onClick={props.gotoHome} className={props.showing==="home"?"home--link active mini-link":"home--link mini-link"}></div>
            <div onClick={props.gotoServices} className={props.showing==="services"?"services--link active mini-link":"services--link mini-link"}></div>
            <div onClick={props.gotoPortfolio} className={props.showing==="portfolio"?"portfolio--link active mini-link":"portfolio--link mini-link"}></div>
            <div onClick={props.gotoExperience} className={props.showing==="experience"?"experience--link active mini-link":"experience--link mini-link"}></div>
            <div onClick={props.gotoContact} className={props.showing==="contact"?"contact--link active mini-link":"contact--link mini-link"}></div>
        </div>
    )
}