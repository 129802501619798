import React from "react";

export default function Services(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const services = document.getElementById("services")
        services.addEventListener("touchend", touchEnd)
        function touchEnd(event){
            const endY = event.changedTouches[0].clientY
            if(endY > startY + 200){
                // props.swipeUp()
                return
            } else if(endY < startY - 200) {
                // props.swipeDown()
                return
            }
        }
    }


    return(
        <div id="services" className={props.className} onTouchStart={touchStarted} >
            <div className="heading">
                <h1 className="title">{props.isFrench?"Métier":"Services"}</h1>
            </div>
            <div className="content">
                <div>
                    <h2>
                        {props.isFrench?`Parlons`:`Let's talk`}
                        <br />
                        {props.isFrench?`de votre projet`:`about your project`}
                    </h2>
                    <div className="gotocontact" onClick={props.gotoContact} >{props.isFrench?"Connectez":"Connect"}</div>
                </div>
                <div className="service">
                    <div className="icon">
                        <i className="fa-brands fa-html5" />
                    </div>
                    <h3>{props.isFrench?"Création de sites Web":"Web Design"}</h3>
                    {props.isFrench?"avec de nombreuses années d'expérience dans les domaines de la conception, je développe et livre de belles applications Web hautement évolutives qui fonctionnent bien sur plusieurs appareils.":"Responsiveness, Speed and Functionality are key in Web Design and Development. Modern design and AI gives your brand an edge in the market"}
                </div>
                <div className="service">
                    <div className="icon" style={{
                        background: "#55c",
                        color: "#fff",
                    }}>
                        <i className="fa-brands fa-react" />
                    </div>
                    <h3>{props.isFrench?"Création de sites Web":"Mobile App Dev_"}</h3>
                    {props.isFrench?"avec de nombreuses années d'expérience dans les domaines de la conception, je développe et livre de belles applications Web hautement évolutives qui fonctionnent bien sur plusieurs appareils.":"Build powerful cross platform mobile applications for ios & android using React Native & Flutter."}
                </div>
                <div className="service">
                    <div className="icon" style={{
                        background: "#000",
                        color: "gold",
                    }}>
                        <i className="fa-brands fa-bitcoin" />
                    </div>
                    <h3>{props.isFrench?"Création de sites Web":"Web3 Dev_"}</h3>
                    {props.isFrench?"avec de nombreuses années d'expérience dans les domaines de la conception, je développe et livre de belles applications Web hautement évolutives qui fonctionnent bien sur plusieurs appareils.":"Web3.0 is the future. I specialize in crafting fulstack Web3 Dapps and Landing pages."}
                </div>
                <div className="service">
                    <div className="icon">
                        <i className="fa-brands fa-figma" />
                    </div>
                    <h3>UI/UX</h3>
                    {props.isFrench?"Je conçois de beaux prototypes qui correspondent à tous vos besoins pour votre entreprise avec une expérience utilisateur transparente et la satisfaction à l'esprit.":"I design beautiful prototypes that matches all your needs for your business with seamless user experience and satisfaction in mind."}
                </div>
            </div>
        </div>
    )
}