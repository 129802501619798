import React from "react";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Home(props){
    function touchStarted(event){
        const startY = event.touches[0].clientY
        const home = document.getElementById("home")
        home.addEventListener("touchmove", touchMove, {passive: true})
        function touchMove(event){
            const endY = event.changedTouches[0].clientY
            home.addEventListener("touchend", touchEnd)

            function touchEnd(){
                if(endY > parseInt(startY + 200)){
                    // props.swipeUp()
                    return
                } else if(endY < startY - 200) {
                    // props.swipeDown()
                    return
                } else{
                    return
                }
            }
        }
    }

    return(
        <div id="home" className={props.className} onTouchStart={touchStarted}>
            <div className="content">
                <div className="left">
                    <div className="image">
                        <div  className="secondary-links">
                            <div>
                                <a href="https://linkedin.com/in/bytegen-dev" target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-linkedin-in" />
                                </a>
                                <a href="https://github.com/bytegen-dev"s target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-github" />
                                </a>
                            </div>
                            <div>
                                <a href="https://twitter.com/bytegen_dev" target="_blank" rel="noreferrer">
                                    {/* <i  className="fa-brands fa-twitter" /> */}
                                    <FaXTwitter />
                                </a>
                                <a href="https://t.me/bytegen_dev" target="_blank" rel="noreferrer">
                                    <i  className="fa-brands fa-telegram-plane" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right">
                    <h1 className="name"><span>{"isaac"}</span> <span>{"adebayo"}</span><span style={{display: "flex"}}><i className="fa fa-check"/><i className="fa fa-certificate"/></span></h1>
                    <h3 className="role">{props.isFrench?"développeur react":"Fullstack developer"}</h3>
                    {/* <span className="about">motivated frontend developer with a keen eye for intuitive and responsive web applications that would look great on every device</span> */}
                    <span className="about">{props.isFrench?`Bonjour, je suis Isaac Adebayo, un développeur react motivé et concepteur UI / UX avec un œil vif pour les applications Web belles et réactives.`:`Hello, I'm Isaac, A Fullstack Web & Mobile app Developer with a keen eye for beautiful, responsive and functional user interfaces.`} </span>
                    <a className="download cv" href="https://github.com/bytegen-dev" target="_blank" rel="noreferrer" >{props.isFrench?"Github":"My Github"}<FaGithub /></a>
                </div>

                <div className="primary-links">
                    <a href="https://linkedin.com/in/bytegen-dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-linkedin-in" />
                    </a>
                    <a href="https://www.facebook.com/bytegen-dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-facebook-f" />
                    </a>
                    <a href="https://github.com/bytegen-dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-github" />
                    </a>
                    <a href="https://twitter.com/bytegen_dev" target="_blank" rel="noreferrer">
                        <i  className="fa-brands fa-twitter" />
                    </a>
                </div>
            </div>
        </div>
    )
}