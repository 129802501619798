import React from "react";

export default function Navbar(props){
    function reload(){
        window.location.reload()
    }

    return(
        <div className={props.className}>
            <div className="content">
                <div className="logo" onClick={reload}>
                    {"IA"}
                </div>
                <div className="links-holder">

                    <div onClick={props.gotoHome} className={props.showing === "home"?"home--link link active":"home--link link"}>{props.isFrench?"Page d'accueil":"Home"}</div>

                    <div onClick={props.gotoServices} className={props.showing === "services"?"services--link link active":"services--link link"}>{props.isFrench?"Métier":"Services"}</div>

                    <div onClick={props.gotoPortfolio} className={props.showing === "portfolio"?"portfolio--link link active":"portfolio--link link"}>{props.isFrench?"Portefeuille":"Portfolio"}</div>

                    <div onClick={props.gotoExperience} className={props.showing === "experience"?"experience--link link active":"experience--link link"}>{props.isFrench?"Expérience":"Experience"}</div>

                    <div onClick={props.gotoContact} className={props.showing === "contact"?"contact--link link active":"contact--link link"}>{props.isFrench?"Connectez":"Connect"}</div>
                    <div className={props.isFrench ? "switch-lang isfrench" : "switch-lang"} onClick={props.toggleLanguage}>
                        <img src="uk-flag.png" alt="english-uk" />
                        <img src="france-flag.png" alt="french" />
                    </div>
                </div>
                <div className={props.showMenu ? "show hamburger":"hamburger"} onClick={props.toggleMenu}>
                    <div className="bar top"></div>
                    <div className="bar mid"></div>
                    <div className="bar bottom"></div>
                </div>
            </div>
        </div>
    )
}