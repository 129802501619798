import React from "react";

export default function Preloader(props){
    return(
        <div className={props.className}>
            <div className="holder">
                <div className="pre-item"></div>
                <div className="pre-item"></div>
            </div>
            {/* <div className="pre-item"></div> */}
        </div>
    )
}